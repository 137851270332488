import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import React, { FunctionComponent } from "react"
import { PageQuery } from "../../graphql-types"
import FilterButton from "../components/FilterButton"
import GoBackButton from "../components/GoBackButton"
import Layout from "../components/Layout"
import htmlSerializer from "../utils/html-serializer"
import { simpleLang } from "../utils/i18n"

export const query = graphql`
  query Page($uid: String!, $lang: String!) {
    prismic {
      page(uid: $uid, lang: $lang) {
        _meta {
          lang
        }
        title
        body
      }
    }
  }
`

type ServiceProps = {
  data: PageQuery
}

const Service: FunctionComponent<ServiceProps> = props => {
  const page = props.data.prismic.page

  if (!page) return null

  const title = page.title[0].text

  const lang = simpleLang(page._meta.lang)

  return (
    <Layout title={title} lang={lang}>
      <div className="m-4">
        <h1>{title}</h1>
        <RichText render={page.body} htmlSerializer={htmlSerializer} />
      </div>
      <GoBackButton />
      <FilterButton lang={lang} />
    </Layout>
  )
}

export default Service
