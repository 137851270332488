import React from "react"

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key })
}

export default function (type, element, content, children, key) {
  switch (type) {
    case "paragraph":
      return React.createElement(
        "p",
        propsWithUniqueKey({ className: "my-4" }, key),
        children
      )
    case "group-list-item":
      return React.createElement(
        "ul",
        propsWithUniqueKey(
          { className: "pl-4 my-4 list-disc list-inside" },
          key
        ),
        children
      )
  }

  return null
}
